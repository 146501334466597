<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 會員資料
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div>商戶ID:{{info.id}}</div>
            <div class="info-content">
                <div style="flex:1">{{info.name}}</div>
                <el-divider direction="vertical"></el-divider>
                <div style="flex:1">{{info.phone_number}}</div>
                <el-divider direction="vertical"></el-divider>
                <div style="flex:1">{{info.account}}</div>
                <el-divider direction="vertical"></el-divider>
                <div class="text-green"
                     style="flex:1">網域來源: <span v-if="info.account_count">{{info.account_count}}</span><span v-else>0</span></div>
                <div class="text-green"
                     style="flex:1">域名: <span v-if="info.domain_count">{{info.domain_count}}</span><span v-else>0</span></div>
                <div class="text-green"
                     style="flex:1">即將到期: <span v-if="info.expired_count">{{info.expired_count}}</span><span v-else>0</span></div>
                <el-divider direction="vertical"></el-divider>
                <div style="flex:.5">
                    <el-button type="text"
                               @click="toPage('memberEdit')">編輯</el-button>
                    <el-button type="text"
                               @click="toPage('domainSourceList')">域名來源</el-button>
                </div>
            </div>
            <div>
                <el-tabs v-model="activeSource"
                         type="card"
                         @tab-click="changeSource">
                    <el-tab-pane v-for="(item,index) in sourceList"
                                 :key="index"
                                 :label="item.name"
                                 :name="item.value"></el-tab-pane>
                </el-tabs>
                <el-form :inline="true"
                         :model="formInline"
                         class="demo-form-inline"
                         style="padding-left:10px">
                    <el-form-item label="域名:">
                        <el-input v-model="formInline.domain"
                                  placeholder="請輸入域名"></el-input>
                    </el-form-item>
                    <el-form-item label="來源註記:"
                                  prop="remark">
                        <el-select v-model="formInline.remark"
                                   multiple
                                   collapse-tags
                                   placeholder="請選擇網域來源"
                                   class="selectWidth">
                            <el-option v-for="(item,index) in remarkList"
                                       :label="item.text"
                                       :value="item.value"
                                       :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分享給:">
                        <el-input v-model="formInline.share_to"
                                  placeholder="請填入以分享的商戶ID"></el-input>
                    </el-form-item>
                </el-form>
                <!--查詢按鈕 跟筆數統計 區域 -->
                <el-row class="btn-search-area">
                    <el-col :span="24"
                            class="btn-search-area-col">
                        <el-button type="primary"
                                   @click="handleSearch">搜尋</el-button>
                        <el-button @click="clearSearch">清除</el-button>
                        <div class="count-area">當前域名數 <span class="span-color-primary">{{tableData.length}}</span> 筆, 總共 <span class="span-color-primary">{{totalCount}}</span> 筆</div>
                    </el-col>
                </el-row>
                <!-- 域名列表 -->
                <el-table :data="tableData"
                          @sort-change="handleSortChange"
                          border
                          class="table">
                    <el-table-column prop="domain"
                                     label="域名"
                                     sortable="custom"
                                     align="center" />
                    <el-table-column width="120"
                                     label="來源註記"
                                     align="center">
                        <template #default="scope">
                            <el-tooltip class="item"
                                        effect="dark"
                                        :content="scope.row.remark"
                                        placement="top">
                                <div class="tool-tip-text"> {{scope.row.remark}}</div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="domain_created_at"
                                     label="創建時間"
                                     width="180"
                                     align="center" />
                    <el-table-column prop="domain_expires_at"
                                     label="到期時間"
                                     width="180"
                                     sortable="custom"
                                     align="center" />
                    <el-table-column prop="domain_renew_deadline"
                                     label="可續約時間"
                                     width="180"
                                     sortable="custom"
                                     align="center" />
                    <el-table-column label="自動續費"
                                     align="center">
                        <template #default="scope">
                            <div v-if="scope.row.is_renew_auto=='Y'">是</div>
                            <div v-else>否</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status"
                                     label="域名狀態"
                                     align="center" />
                    <el-table-column prop="updated_at"
                                     label="最後更新時間"
                                     width="180"
                                     sortable="custom"
                                     align="center" />
                    <el-table-column width="120"
                                     label="DNS指向"
                                     align="center">
                        <template #default="scope">
                            <el-tooltip class="item"
                                        effect="dark"
                                        placement="top">
                                <template #content> <template v-for="(server,idx) in scope.row.nameservers"
                                              :key="idx">
                                        {{server}}
                                        <br>
                                    </template>
                                </template>
                                <div class="tool-tip-text"
                                     v-html="nameSeverChange(scope.row.nameservers)"></div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="分享給"
                                     align="center">
                        <template #default="scope">

                            <div v-if="scope.row.share_list !== undefined &&scope.row.share_list.length>0">
                                <template v-for="(item, index) in scope.row.share_list"
                                          :key="index">
                                    <div>{{item.mid}}</div>
                                </template>
                            </div>
                            <div v-else>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination background
                                   layout=" prev, pager, next"
                                   :current-page="pageIndex"
                                   :page-size="pageSize"
                                   :page-count="pageCount"
                                   @current-change="handlePageChange">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { memberList, domainSourceList } from '../../../api/index'

export default {
    data() {
        return {
            info: {},
            sourceList: {},
            remarkList: {},
            tableData: [],
            activeSource: '',
            active: true,
            formInline: {
                domain: '',
                remark: [],
                tag: '',
            },
            pageIndex: 1,
            pageSize: 10,
            pageCount: 0,
            totalCount: 0, //共幾筆?
            inputVisible: false,
            inputValue: '',
            userId: '',
            order: '',
            updateDialogVisible: false, //更新狀態彈出框
        }
    },
    async mounted() {
        await this.getDomainSourceList()
        this.getData()
    },
    methods: {
        async getDomainSourceList() {
            var ret = await new Promise((resolve) => {
                domainSourceList().then((res) => {
                    this.sourceList = res.data.data_list
                    this.activeSource = res.data.data_list[0].value
                    resolve()
                })
            })
            return ret
        },
        getData() {
            let remarkJson = JSON.stringify(this.formInline.remark)
            let data = {
                domain: this.formInline.domain,
                source_id: this.activeSource,
                remark: remarkJson,
                order: this.order,
                share_from: this.formInline.share_from,
                share_to: this.formInline.share_to,
                page: this.pageIndex,
            }
            memberList(data).then((res) => {
                if (res.code == 300) {
                    this.info = res.data.data_info[0]
                    this.remarkList = res.data.remark_select_list
                    this.tableData = res.data.data_list
                    this.pageCount = res.data.total_page
                    this.totalCount = res.data.total_count
                } else if (res.code == 500) {
                    this.$message.error(res.message)
                }
            })
        },
        toPage(page) {
            this.$router.push({
                path: page,
            })
        },
        handleSearch() {
            this.getData()
        },
        clearSearch() {
            this.formInline = []
        },
        handleClick() {},
        //排序
        handleSortChange({ prop, order }) {
            if (order == 'descending') {
                this.order = prop + '_desc'
            } else if (order == 'ascending') {
                this.order = prop + '_asc'
            } else {
                this.order = ''
            }
            this.tableData = []
            this.getData()
        },
        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val
            this.getData()
        },
        //換網域
        changeSource() {
            this.getData()
        },
        openUpdateDialog() {
            this.updateDialogVisible = true
        },
        //dns指向更改
        nameSeverChange(array) {
            let a = array || []
            return a.join('<br>')
        },
    },
}
</script>

<style lang="scss" scoped>
.info-content {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid black;
    padding: 25px 5px;
    border-radius: 5px;
    margin-bottom: 60px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: nowrap;
}

.el-divider--vertical {
    height: 2em;
}
.grid-content {
    text-align: center;
}
.text-green {
    span {
        color: #0a9a0ac2;
        font-size: 20px;
        font-weight: bold;
    }
}
.span-color-primary {
    color: #409eff;
}
.status-update {
    padding-bottom: 20px;
}
.btn-search-area {
    padding: 10px 0 25px 0;
}
.btn-search-area-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.count-area {
    margin-left: 20px;
}
</style>